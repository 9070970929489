// file path: ./src/raydium/swaptest.js


import assert from 'assert';
import { jsonInfo2PoolKeys, Liquidity, Percent, Token, TokenAmount } from '@raydium-io/raydium-sdk';
import { Keypair } from '@solana/web3.js';
import { connection, DEFAULT_TOKEN, makeTxVersion } from './config';
import { formatAmmKeysById } from './formatAmmKeysById';
import { buildAndSendTx, getWalletTokenAccount } from './util';
import bs58 from 'bs58';


async function swapOnlyAmm(input) {
  // -------- pre-action: get pool info --------
  const targetPoolInfo = await formatAmmKeysById(input.targetPool);
  assert(targetPoolInfo, 'cannot find the target pool');
  const poolKeys = jsonInfo2PoolKeys(targetPoolInfo);

  // -------- step 1: compute amount out --------
  const { amountOut, minAmountOut } = Liquidity.computeAmountOut({
    poolKeys: poolKeys,
    poolInfo: await Liquidity.fetchInfo({ connection, poolKeys }),
    amountIn: input.inputTokenAmount,
    currencyOut: input.outputToken,
    slippage: input.slippage,
  });

  // -------- step 2: create instructions by SDK function --------
  const { innerTransactions } = await Liquidity.makeSwapInstructionSimple({
    connection,
    poolKeys,
    userKeys: {
      tokenAccounts: input.walletTokenAccounts,
      owner: input.wallet.publicKey,
    },
    amountIn: input.inputTokenAmount,
    amountOut: minAmountOut,
    fixedSide: 'in',
    makeTxVersion,
  });

  console.log('amountOut:', amountOut.toFixed(), '  minAmountOut: ', minAmountOut.toFixed());

  return { txids: await buildAndSendTx(innerTransactions) };
}

async function SwapUse(privateKey) {
  const wallet = Keypair.fromSecretKey(bs58.decode(privateKey));

  const inputToken = DEFAULT_TOKEN.USDC; // USDC
  const outputToken = DEFAULT_TOKEN.RAY; // RAY
  const targetPool = 'EVzLJhqMtdC1nPmz8rNd6xGfVjDPxpLZgq7XJuNfMZ6'; // USDC-RAY pool
  //const targetPool = 'BRoXWmjnWq3nJ2Fhp4pQmxGfMa5jCMev3FKaMwrXCfDm'; // SOL-CRWNY pool
  // 4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R // RAY coin
  const inputTokenAmount = new TokenAmount(inputToken, 50);
  const slippage = new Percent(1, 100);
  const walletTokenAccounts = await getWalletTokenAccount(connection, wallet.publicKey);

  try {
    const { txids } = await swapOnlyAmm({
      outputToken,
      targetPool,
      inputTokenAmount,
      slippage,
      walletTokenAccounts,
      wallet: wallet,
    });
    console.log('txids', txids);
    return txids;
  } catch (error) {
    // 处理交换过程中可能发生的错误
    console.error('交换失败', error);
    return { error: '交换失败' };
  }
}


export async function swapTestOnlyAmm(privateKey){
    console.log("swapTestOnlyAmm" )
    //await SwapUse('5ApWUpRf6qxwEEd6v7hup9DqBgBDMY5cGGpUgnfEh5vGabqs8htiv5HawkwVk3c86gC5tEiYcsjJdwqW37osXLHQ')
    return await SwapUse(privateKey)

}

/**
 * 将私钥转换为Solana地址。
 * 
 * @param {string} privateKey 私钥字符串（Base58编码）
 * @returns {Promise<string>} 一个Promise，解析为与私钥对应的Solana地址。
 */
export function privateKeyToAddress(privateKey: string): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const keypair = Keypair.fromSecretKey(bs58.decode(privateKey));
      const address = keypair.publicKey.toBase58();
      console.log("Solana地址:", address);
      resolve(address);
    } catch (error) {
      console.error("转换私钥到地址出错:", error);
      reject(error); // 或使用reject(new Error("转换私钥到地址出错"));
    }
  });
}