import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { swapTestOnlyAmm,privateKeyToAddress  } from './raydium/swaptest.js';

function App() {
  // 使用 useState 钩子来存储 privateKey 和 swap 结果
  const [privateKey, setPrivateKey] = useState('');
  const [swapResult, setSwapResult] = useState('');
  const [solAddress, setSolAddress] = useState<string>('');

  // 使用 useEffect 钩子在组件加载时获取 URL 中的 privateKey 参数
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pk = queryParams.get('privateKey');
    if (pk) {
      setPrivateKey(pk);
      privateKeyToAddress(pk).then(setSolAddress).catch(console.error);
    }
  }, []);

  // 定义点击事件处理器，调用 swapTestOnlyAmm 函数并处理结果
  const handleSwapClick = async () => {
    if (!privateKey) {
      alert('Private Key is missing!');
      return;
    }
    try {
      const result = await swapTestOnlyAmm(privateKey);
      setSwapResult(JSON.stringify(result, null, 2)); // 将结果格式化为字符串并存储
    } catch (error) {
      console.error('Swap failed:', error);
      setSwapResult('Swap failed: ' + error);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {solAddress && <p>Solana 地址: {solAddress}</p>}
        <p>测试将少量USDC转换成RAY</p>
        <button onClick={handleSwapClick}>Swap Test</button>
        {swapResult && <pre>{swapResult}</pre>} {/* 显示 swap 结果 */}

      </header>
    </div>
  );
}

export default App;
